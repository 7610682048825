import { Button } from "@blueprintjs/core"
import { DateInput as BPDateInput } from "@blueprintjs/datetime"

function toLocalDate(dateStr: string = "") {
  const utcDate = new Date(dateStr).getTime()
  const currentOffset = new Date().getTimezoneOffset()
  const dateOffset = new Date(dateStr).getTimezoneOffset()

  const offset = currentOffset < dateOffset ? dateOffset : currentOffset

  return new Date(utcDate + offset * 1000 * 60)
}

function defaultFormattedDate(date: Date) {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  return month + "/" + day + "/" + year
}

type DateInputProps = {
  value?: string | null
  onChange?: (selectedDate: Date | string | null) => void
  formatDate?: (d: Date) => string
  parseDate?: (d: string) => false | Date | null
}

export default function DateInput({
  value,
  onChange,
  formatDate = defaultFormattedDate,
  parseDate = (d: string) => new Date(d),
}: DateInputProps) {
  const maxDate = new Date()
  maxDate.setFullYear(new Date().getFullYear() + 2)

  // minDate should go back to year 1900
  const minDate = new Date()
  minDate.setFullYear(1900)

  return (
    <BPDateInput
      highlightCurrentDay={false}
      value={value ? toLocalDate(value) : null}
      defaultValue={new Date()}
      onChange={(date) => {
        if (onChange) {
          onChange(date ? date.toLocaleDateString("en-CA") : date)
        }
      }}
      parseDate={parseDate}
      formatDate={formatDate}
      maxDate={maxDate}
      minDate={minDate}
      inputProps={{
        rightElement: value ? (
          <Button
            minimal
            icon="cross"
            onClick={(evt) => {
              evt.preventDefault()
              evt.stopPropagation()
              if (onChange) {
                onChange("")
              }
            }}
          />
        ) : undefined,
      }}
    />
  )
}
