import { Signatory, SignatoryWithAuthReps } from "models/Signatory"

type TSignatory = {
  representatives: {
    signatoryId: string
    title?: string
  }[]
}

export default function buildAuthRepTreeForEntities<T extends Signatory>(
  signatories: T[] = []
) {
  return signatories.map((signatory) =>
    buildAuthRepTreeForEntity(
      {
        ...signatory,
      },
      signatories
    )
  )
}

export function buildAuthRepTreeForEntity<T extends TSignatory>(
  signatory: T,
  signatories: Signatory[] = []
): T & { authReps: SignatoryWithAuthReps[] } {
  const representatives = signatory?.representatives?.map((rep) => rep)
  if (representatives?.length && representatives?.length > 0) {
    const authReps = signatories.filter((signatory) =>
      representatives.some((rep) => rep.signatoryId === signatory.id)
    )
    if (authReps?.length > 0) {
      return {
        ...signatory,
        authReps: authReps.map((authRep) =>
          buildAuthRepTreeForEntity(
            {
              ...authRep,
              title:
                representatives.find((rep) => rep.signatoryId === authRep.id)
                  ?.title || "",
            },
            signatories
          )
        ),
      }
    }
  }
  return {
    ...signatory,
    authReps: [],
  }
}

export function findAuthRepsForEntity(
  signatory: Signatory,
  signatories: Signatory[] = []
) {
  const authRepIds = signatory.representatives?.map(
    ({ representative }) => representative
  )

  if (authRepIds?.length > 0) {
    return signatories.filter(
      (sig) => authRepIds.includes(sig.uuid) && !sig.isOrg
    )
  }
  return []
}

type SignatoryWithAuthRep =
  | (Signatory & {
      authRep: Signatory | null
      title?: string
    })
  | null

export function findAuthRepTreeForEntity(
  signatory: Signatory,
  signatories: Signatory[] = []
): SignatoryWithAuthRep {
  const { signatoryId: authRepId, title } = signatory.representatives[0] || {}
  if (authRepId) {
    const authRep = signatories.find(({ id }) => authRepId === id)
    if (authRep) {
      return {
        ...authRep,
        title,
        authRep: findAuthRepTreeForEntity(authRep, signatories),
      }
    }
  }
  return null
}

export function findAuthRepLeafForEntities(
  signatory: {
    representatives: {
      signatoryId: string
      title?: string
      order: number
    }[]
  },
  signatories: Signatory[] = []
) {
  return (
    signatory?.representatives?.map((rep) => {
      const { signatoryId: authRepId, title, order } = rep || {}
      if (authRepId) {
        const authRep = signatories.find(({ id }) => authRepId === id)
        if (authRep) {
          return {
            ...authRep,
            title,
            order,
            authRep: findAuthRepTreeForEntity(authRep, signatories),
          }
        }
        return null
      }
    }) || []
  ).filter((rep) => rep) // filter out nulls
}
