import * as React from "react"
import { useRouter } from "next/router"

import { Icon } from "@blueprintjs/core"

import UserEngagement from "app/user-engagement"
import { useTriggerSurvey } from "app/user-engagement/use-survey"

import TransactionForm from "components/forms/Transaction"
import Logo from "components/logo"
import { Sidebar } from "components/nav"
import {
  NotificationIcon,
  NotificationsFormatter,
} from "components/notification"

import UpgradeAccountModal from "features/transactions/UpgradeAccountModal"
import { useCreateTransaction } from "features/transactions/api"
import { useCurrentUser, useCurrentOrgModules } from "features/auth/withAuth"
import {
  APITransaction,
  CreatableTransaction,
  TransactionKind,
} from "models/Transaction"
import CreateOrEditProjectForm from "features/projects/CreateOrEditProjectForm"
import { ApiError } from "lib/api/api-http"

type TransactionSidebarProps = {
  children?: React.ReactNode
}

export const TransactionSidebar = ({ children }: TransactionSidebarProps) => {
  const [isShowingUpgradeAccountModal, setIsShowingUpgradeAccountModal] =
    React.useState(false)

  const { currentUser } = useCurrentUser()
  const { hasClosingTool, hasFundingTool } = useCurrentOrgModules()
  const { triggerSurvey } = useTriggerSurvey()

  const { push } = useRouter()

  const { createTransaction, isLoading } = useCreateTransaction({
    onError: (error: unknown) => {
      if (error instanceof ApiError) {
        if (error.status === 403) {
          setIsShowingUpgradeAccountModal(true)
        }
      }
    },
    onSuccess: (transaction: APITransaction) => {
      if (currentUser) {
        triggerSurvey(currentUser)
      }

      UserEngagement.track("TRANSACTION_CREATED", {
        id: transaction.uuid,
      })

      if (transaction.kind === TransactionKind.WIRES) {
        push("/projects/[id]", `/projects/${transaction.uuid}`)
      } else {
        push("/transaction/[id]", `/transaction/${transaction.uuid}`)
      }
    },
  })

  const handleCreateTransactionSubmit = async (
    {
      clientName,
      clientNumber,
      description,
      isDeedTransaction,
      isSample,
      kind,
      matterNumber,
      name,
    }: CreatableTransaction,
    resetForm?: () => void
  ) => {
    try {
      await createTransaction({
        clientName,
        clientNumber,
        description,
        isDeedTransaction,
        isSample,
        kind,
        matterNumber,
        name: isSample ? `${name} [Test]` : name,
      })
    } catch (e) {
      console.log(e)
    }
    if (resetForm) {
      resetForm?.()
    }
  }

  const isFundingToolOnlyOrg = hasFundingTool && !hasClosingTool

  return (
    <div>
      <div className="fixed top-0 z-20 flex h-full">
        <Sidebar>
          {isShowingUpgradeAccountModal && (
            <UpgradeAccountModal
              onClose={() => setIsShowingUpgradeAccountModal(false)}
            />
          )}
          <Sidebar.Menu alwaysExpanded>
            <Sidebar.Logo>
              <Logo width="24px" height="24px" />
            </Sidebar.Logo>
            <Sidebar.MenuItem>
              <NotificationIcon color="#182026" size={20} />
            </Sidebar.MenuItem>
            <Sidebar.MenuItem defaultSelected>
              <Icon icon="add" color="#182026" size={20} />
            </Sidebar.MenuItem>
          </Sidebar.Menu>
          <Sidebar.Panels fullHeight>
            <Sidebar.MenuPanel title="Notifications" key="notifications">
              <NotificationsFormatter />
            </Sidebar.MenuPanel>
            <Sidebar.MenuPanel
              title={`Create New ${
                isFundingToolOnlyOrg ? "Project" : "Transaction"
              }`}
              key="newTransaction"
            >
              {((!hasClosingTool && !hasFundingTool) || hasClosingTool) && (
                <TransactionForm
                  submitButtonProps={{}}
                  handleSubmit={handleCreateTransactionSubmit}
                />
              )}
              {isFundingToolOnlyOrg && (
                <CreateOrEditProjectForm
                  initialValues={{
                    id: "",
                    name: "",
                    description: "",
                    isSample: false,
                    clientName: undefined,
                    clientNumber: undefined,
                    matterNumber: undefined,
                    isDeedTransaction: false,
                    kind: TransactionKind.WIRES,
                  }}
                  onSubmit={handleCreateTransactionSubmit}
                  isLoading={isLoading}
                />
              )}
            </Sidebar.MenuPanel>
          </Sidebar.Panels>
        </Sidebar>
      </div>
      {children}
    </div>
  )
}
