import React, { FormEvent, useEffect, useState } from "react"

import { useTransactionIdParam } from "helpers/params"

import { useUpdateItem } from "features/transaction/id/api"

import { encodePage } from "components/item-detail/encode"

import applyVersionToItem from "./apply-version-to-item"
import mapThumbnailsToPages from "./mappings/thumbnails-to-pages" // VersionPage, // Placeholder,
import moveMetaPage from "./move-meta-page"
import toggleMetaPageType from "./toggle-meta-page-type"
import { apiClient } from "lib/api"
import { UIItem, UIItemPage } from "models/UIItem"

type VersionFormProps = {
  children: React.ReactElement
  item: UIItem
  version?: Record<string, any>
  onDocUploadComplete?: () => void
  onClose: () => void
}

export type TPage = {
  index: number
  metaPage?: UIItemPage
  pageType: "page"
  url: string
}

export type TPlaceholder = {
  index: number
  pageType: "placeholder"
  metaPage?: UIItemPage
  pageIndex: number
}

type PageData = {
  pages: TPage[]
  placeholders: TPlaceholder[]
}

export default function VersionForm({
  children,
  version = {},
  onDocUploadComplete,
  item,
  ...props
}: VersionFormProps) {
  const transactionId = useTransactionIdParam()
  const [pageData, setPages] = useState<PageData>({
    pages: [],
    placeholders: [],
  })
  const { pages, placeholders } = pageData
  const [isSaving, setIsSaving] = useState(false)
  const [isClearingPendingDocument, setIsClearingPendingDocument] =
    useState(false)
  const [itemStatus, setItemStatus] = useState("to_be_signed")

  const isPlaceholderItem =
    item.pages.length === 0 || item.pages.every((p) => p.type === "instapagev2")

  useEffect(() => {
    if (version.pageThumbnails) {
      if (version.pageThumbnails.length > 0) {
        const [pages, placeholders] = mapThumbnailsToPages(
          version.pageThumbnails,
          item.pages,
          isPlaceholderItem
        )
        setPages({ pages, placeholders })
        onDocUploadComplete?.()
      }
    }
  }, [version.pageThumbnails, item.pages])

  const { updateItem } = useUpdateItem(transactionId, {
    onSuccess: () => {
      apiClient.refetchQueries(
        ["transactions", transactionId, "documents-w-thumbs"],
        {
          exact: true,
        }
      )
    },
  })

  function handleMove(item: TPage | TPlaceholder, toIndex: number) {
    setPages(moveMetaPage(item, toIndex, pageData))
  }

  function handleTogglePageType(item: TPage | TPlaceholder) {
    setPages(toggleMetaPageType(item, pageData))
  }

  const handleStatusChange = ({
    currentTarget: { value },
  }: FormEvent<HTMLInputElement>) => {
    if (
      value === "to_be_signed" ||
      value === "non_executable" ||
      value === "fully_executed"
    ) {
      setItemStatus(value)
    }
  }

  async function handleSubmit() {
    setIsSaving(true)
    await updateItem({
      id: item.id,
      pendingVersionDocumentId: null,
      pages: applyVersionToItem(pageData, version.id).map(encodePage),
      forSigning: itemStatus !== "non_executable",
      fullyExecuted: itemStatus === "fully_executed",
    })

    props.onClose()
  }

  async function handleClearPendingDocument() {
    setIsClearingPendingDocument(true)
    await updateItem({ id: item.id, pendingVersionDocumentId: null })
    props.onClose()
  }

  return React.cloneElement(React.Children.only(children), {
    ...props,
    onMovePage: handleMove,
    onToggleSignaturePageType: handleTogglePageType,
    onStatusChange: handleStatusChange,
    onSubmit: handleSubmit,
    onClearPendingDocument: handleClearPendingDocument,
    isClearingPendingDocument,
    isSubmitting: isSaving,
    selectedStatusValue: itemStatus,
    pages,
    placeholders,
    version,
  })
}
