import { ReactNode } from "react"
import PrefetchLink from "lib/prefetch-link"
import { apiClient } from "lib/api"

import {
  getAssignments,
  getItems,
  getTransactionDashboardSignatories,
  getTransaction,
  getDocuments,
  getTransactionElements,
} from "helpers/api"
import { TransactionKind } from "models/Transaction"

function getQueries(id: string) {
  return [
    {
      key: ["transaction", id],
      fn: () => getTransaction(id),
    },
    {
      key: ["transactions", id, "signatories"],
      fn: () => getTransactionDashboardSignatories(id),
    },
    {
      key: ["transactions", id, "items"],
      fn: () => getItems(id),
    },
    {
      key: ["transactions", id, "transactionElements"],
      fn: () => getTransactionElements(id),
    },
    {
      key: ["transactions", id, "assignments"],
      fn: () => getAssignments(id),
    },
    {
      key: [id, "documents", { inQueue: true }],
      fn: () => getDocuments(id, false, true),
      staleTime: 3000,
    },
  ]
}

export function prefetchTransactionQueries(id: string) {
  getQueries(id).forEach((query) =>
    apiClient.prefetchQuery(query.key, query.fn, { staleTime: 15000 })
  )
}

type TransactionPrefetchLinkProps = {
  id: string
  children: ReactNode
  kind: string
}

export default function TransactionPrefetchLink({
  children,
  id,
  kind,
}: TransactionPrefetchLinkProps) {
  const prefetchQueries = getQueries(id)

  return (
    <PrefetchLink
      href={
        kind === TransactionKind.WIRES
          ? `/projects/${id}`
          : `/transaction/${id}`
      }
      queries={prefetchQueries}
      staleTime={15000}
    >
      <div className="grow hover:underline text-blue-6 hover:cursor-pointer">
        {children}
      </div>
    </PrefetchLink>
  )
}
