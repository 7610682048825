import { Button, Intent } from "@blueprintjs/core"

import UserEngagement from "app/user-engagement"
import SelectFileButtons from "../SelectFileButtons"
import { useTransactionIdParam } from "helpers/params"
import { useUploadQueueState } from "../useUploadQueueState"
import pluralize from "helpers/pluralize"
import { useUploadQueueContext } from "../UploadQueueContext"

type DocumentsQueueFooterProps = {
  onOpenDMS: () => void
  openFileDialog: () => void
  onAddToTransaction: () => void
}

export default function DocumentsQueueFooter({
  onOpenDMS,
  openFileDialog,
  onAddToTransaction,
}: DocumentsQueueFooterProps) {
  const transactionId = useTransactionIdParam()
  const { isLoading } = useUploadQueueState()
  const { state } = useUploadQueueContext()

  const selectedDocuments =
    state[transactionId]?.files?.filter(
      (f) => f.isSelected && f.status !== "REMOVED"
    ) ?? []
  const selectedDocumentFailures = selectedDocuments.filter((f) =>
    f.status.includes("FAILED")
  )

  return (
    <div className="w-full border-t p-4 z-1 h-20 grid grid-cols-[minmax(0,_1fr)_300px] lg:grid-cols-[minmax(0,_1fr)_500px]">
      <SelectFileButtons
        showDragText
        onOpenDMS={onOpenDMS}
        openFileDialog={openFileDialog}
      />
      <div>
        <Button
          id="add-to-transaction-btn"
          className="w-full h-[40px] rounded"
          icon="plus"
          intent={selectedDocuments.length > 0 ? Intent.PRIMARY : Intent.NONE}
          onClick={() => {
            UserEngagement.track("ADD_TO_TRANSACTION_CLICKED", {
              transactionId,
            })
            onAddToTransaction()
          }}
          disabled={
            !selectedDocuments.every(
              (d) => d.status === "CONVERSION_COMPLETE"
            ) ||
            selectedDocumentFailures.length > 0 ||
            selectedDocuments.length <= 0 ||
            isLoading
          }
        >
          Add{" "}
          {`${selectedDocuments.length} ${pluralize(
            "document",
            selectedDocuments.length
          )}`}{" "}
          to transaction
        </Button>
      </div>
    </div>
  )
}
