import { ApiHttp as http } from "lib/api"
import {
  APITransaction,
  APITransactionFeatureFlags,
  CreatableAPITransaction,
  UpdatableAPITransaction,
} from "models/Transaction"
import { APIAssignment, UpdatableAPIAssignment } from "models/Assignment"
import { APIInvitation, CreatableAPIInvitation } from "models/Invitation"
import {
  APIItem,
  CreatableAPIItem,
  ItemDownloadKindType,
  UpdatableAPIItem,
} from "models/Item"
import { APITask, CreatableAPITask, UpdatableAPITask } from "models/Task"
import { UpdatableAPINotification } from "models/Notification"
import {
  APISignatory,
  CreatableAPISignatory,
  Signatory,
  UpdatableAPISignatory,
} from "models/Signatory"
import { APITab, CreatableAPITab, UpdatableAPITab } from "models/Tab"
import {
  APIDocument,
  CreatableAPIDocument,
  UpdateableAPIDocument,
} from "models/Document"
import { APIUser, UpdatableAPIUser } from "models/User"
import { APIParticipant } from "models/Participant"
import { DetectPage } from "models/Pages"
import { APISignerAuditLog } from "models/SignerAuditLog"
import {
  APIRepresentativeDetails,
  CreatableAPIRepresentative,
} from "models/Representative"
import { APIOrganization } from "models/Organization"
import {
  APISigningGroup,
  CreatableAPISigningGroup,
  UpdatableAPISigningGroup,
} from "models/SigningGroup"
import {
  APISigningGroupMember,
  BulkSigningGroupsMembers,
  CreatableAPISigningGroupMember,
  MoveAPISigningGroupMember,
} from "models/SigningGroupMember"
import { APIPacketRequest, APIPacketRequestReponse } from "models/PacketRequest"
import {
  APITransactionElement,
  CreatableAPITransactionElement,
} from "models/TransactionElement"
import { APIClosingBinderTemplate } from "models/ClosingBinderTemplate"
import {
  APICreateTransactionCustomField,
  APITransactionCustomField,
  APIUpdatableTransactionCustomField,
} from "models/InstaPageV2/CustomFields/TransactionCustomField"
import {
  APICreateSignatoryCustomFieldValue,
  APISignatoryCustomFieldValue,
} from "models/InstaPageV2/CustomFields/SignatoryCustomFieldValue"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { APISignatureBlockTemplate } from "models/InstaPageV2/SignatureBlockTemplate"
import { APINote, CreatableAPINote } from "models/Note"
import { APISignatoryUploadRow } from "models/SignatoryUpload"
import { APIBulkSignatoryUpload } from "models/BulkSignatoryUpload"

export const createOrUpdateBinder = (binderId: string, binder) =>
  binderId
    ? http.patch(`/closing_binder/${binderId}`, binder)
    : http.post(`/closing_binder/`, binder)

export const createClosingBinderIndexPreview = (closing_binder) =>
  http.post(`/closing_binder/preview`, { closing_binder })

export const getBinderIndexPreview = (previewId: string) =>
  http.get(`/closing_binder/preview/${previewId}`)

export const deleteBinder = (binderId: string) =>
  http.delete(`/closing_binder/${binderId}`)

export const getSignatoryUploadTemplate = (transactionId: string) =>
  http.get<{ url: string }>(
    `/transactions/transaction/${transactionId}/get_signatory_upload_template`
  )

export const getClosingBinder = (binderId: string) =>
  http.get(`/closing_binder/${binderId}`)

export const getClosingBinders = (transactionId: string) =>
  http.get(`/closing_binder/?transaction=${transactionId}`)

export const postBuildBinder = (binderId: string) =>
  http.post(`/closing_binder/${encodeURIComponent(binderId)}/build`, null)

export const postCancelBuildBinder = (binderId: string) =>
  http.post(
    `/closing_binder/${encodeURIComponent(binderId)}/cancel_build`,
    null
  )

export const getClosingBinderTemplates = (transactionId: string) =>
  http.get<APIClosingBinderTemplate[]>(
    `/closing_binder/template?transaction=${transactionId}`
  )

export const getInvitations = async (orgId?: string) =>
  (await http.get<APIInvitation[]>(
    `/invitations/invitation${orgId ? `?org_invitations=true` : ""}`
  )) || []

export const postInvitation = async (invitation: CreatableAPIInvitation) =>
  http.post<APIInvitation>(`/invitations/invitation`, invitation)

export const postInvitationAccept = async (token: string) =>
  http.post<{ participant: string; transaction: string; kind: string }>(
    "/invitations/invitation/accept",
    { token }
  )

export const deleteOrgInvitation = async (invitationId: string) =>
  // Assuming these are always org invitations?
  http.delete(`/invitations/invitation/${invitationId}?org_invitations=true`)

export const deleteInvitation = async (id: string) =>
  http.delete(`/invitations/invitation/${encodeURIComponent(id)}`)

export const getInvitationPreview = (invitationId: string) =>
  http.get<APIInvitation>(`/invitations/invitation/${invitationId}/preview`)

export const createTransaction = (payload: CreatableAPITransaction) =>
  http.post<APITransaction>(`/transactions/transaction`, payload)

export const updateTransaction = (
  transactionId: string,
  payload: UpdatableAPITransaction,
  query?: Record<string, string | boolean>
) => {
  let queryParams = new URLSearchParams(query)

  return http.patch<APITransaction>(
    `/transactions/transaction/${transactionId}${
      queryParams.size ? `?${queryParams}` : ""
    }`,
    payload
  )
}

export const deleteTransaction = (transactionId: string) =>
  http.delete<null>(`/transactions/transaction/${transactionId}`)

export const getTransaction = async (transactionId: string) =>
  await http.get<APITransaction>(`/transactions/transaction/${transactionId}`)

export const getTransactionReport = (transactionId: string) =>
  http.get(
    `/transactions/transaction/${encodeURIComponent(
      transactionId
    )}/signing_status_report`
  )

export const getTransactionMatrixReport = (transactionId: string) =>
  http.get(
    `/transactions/transaction/${encodeURIComponent(
      transactionId
    )}/signing_matrix_report`
  )

export const postTransactionDownload = (
  transactionId: string,
  body: { kind: ItemDownloadKindType }
) => http.post(`/transactions/transaction/${transactionId}/download`, body)

export const postTransactionSignatureBlockTemplate = (
  transaction: string,
  data: SignatureBlock
) =>
  http.post("/transactions/signature_block", {
    transaction,
    data,
  })

export const patchSignatureBlockTemplate = (
  templateId: string,
  data: SignatureBlock
) =>
  http.patch(`/transactions/signature_block/${templateId}`, {
    data,
  })

export const setTransactionArchive = (
  transactionId: string,
  archived: boolean
) =>
  http.post(`/transactions/transaction/${transactionId}/participant_settings`, {
    archived,
  })

export const leaveTransactionAsParticipant = (transactionId: string) =>
  http.post(`/transactions/transaction/${transactionId}/participant_leave`, {})

export const addTransactionParticipant = (
  orgId: string,
  transactionId: string,
  userId: string
) =>
  http.post<"success">(
    `/authentication/organization/${orgId}/create_participant`,
    {
      transaction: transactionId,
      user: userId,
    }
  )

export const deleteTransactionParticipant = (
  orgId: string,
  transactionId: string,
  userId: string
) =>
  http.post<null>(`/authentication/organization/${orgId}/delete_participant`, {
    transaction: transactionId,
    user: userId,
  })

export const getTransactionParticipants = (transactionId: string) =>
  http.get<APIParticipant[]>(
    `/transactions/participant?transaction=${transactionId}`
  )

export const deleteParticipant = (participantId: string) =>
  http.delete<null>(
    `/transactions/participant/${encodeURIComponent(participantId)}`
  )

export const createTransactionParticipant = ({
  transactionId,
  userId,
}: {
  transactionId: string
  userId: string
}) =>
  // TODO-TS: possibly create model type for this?
  http.post<{
    uuid: string
    user_uuid: string
    transaction_uuid: string
  }>(`/transactions/participant`, {
    transaction_uuid: transactionId,
    user_uuid: userId,
  })

export const getItem = (itemId: string) =>
  http.get<APIItem>(`/transactions/item/${itemId}`)

export const getItems = async (transactionId: string) =>
  (await http.get<APIItem[]>(
    `/transactions/item?transaction=${transactionId}`
  )) || []

export const getTask = (taskId: string) =>
  http.get<APITask>(`/transactions/task/${taskId}`)

export const getTasks = async (transactionId: string) =>
  (await http.get<APITask[]>(
    `/transactions/task?transaction=${transactionId}`
  )) || []

export const getTransactionElements = async (transactionId: string) =>
  (await http.get<APITransactionElement[]>(
    `/transactions/element?transaction=${transactionId}`
  )) || []

export const getTransactionFeatureFlags = async (transactionId: string) =>
  await http.get<APITransactionFeatureFlags>(
    `/feature-status?transaction=${transactionId}`
  )

export const getSignatureBlockTemplates = async (transactionId: string) =>
  await http.get<APISignatureBlockTemplate[]>(
    `/transactions/signature_block?transaction=${transactionId}`
  )

export const createTransactionElement = ({
  item,
  label,
  order,
  parent_element,
  transaction,
}: CreatableAPITransactionElement) => {
  return http.post<APITransactionElement>(`/transactions/element`, {
    item,
    label,
    order,
    parent_element,
    transaction,
  })
}

export const reorderTransactionElements = (
  data: { uuid: string; parent_element?: string }[]
) => {
  return http.post<APITransactionElement>(`/transactions/element/reorder`, {
    ...data,
  })
}

export const bulkElementUpdate = (data: {
  ids: string[]
  order: number
  parentElement: string | null
}) => {
  return http.patch<APITransactionElement>(
    `/transactions/element/bulk_update`,
    {
      element_uuids: data.ids,
      order: data.order,
      parent_element: data.parentElement,
    }
  )
}

export const getTransactionElement = async (
  id: string,
  transactionId: string
) =>
  (await http.get<APITransactionElement>(
    `/transactions/element/${id}?transaction=${transactionId}`
  )) || null

export const updateTransactionElement = async (
  transactionId: string,
  transactionElement: APITransactionElement
) =>
  (await http.patch<APITransactionElement>(
    `/transactions/element/${transactionElement.uuid}?transaction=${transactionId}`,
    {
      ...transactionElement,
    }
  )) || null

export const updatePartialTransactionElement = async (
  transactionId: string,
  transactionElement: Partial<APITransactionElement>
) =>
  (await http.patch<APITransactionElement>(
    `/transactions/element/${transactionElement.uuid}?transaction=${transactionId}`,
    {
      ...transactionElement,
    }
  )) || null

export const deleteTransactionElement = (id: string, transactionId: string) =>
  http.delete<null>(`/transactions/element/${id}?transaction=${transactionId}`)

export const getItemPageSignatures = async (page: DetectPage) =>
  (await http.post<APIAssignment[]>(
    `/transactions/item/${page.itemId}/detect_signatures`,
    {
      document: page.refId,
      page: page.refPageNumber,
    }
  )) || []

export const createItemsFromDocuments = (documents: string[]) =>
  http.post<{ document_id: string; item_id: string }[]>(
    `/transactions/item/from_documents`,
    { documents }
  )

export const deleteItem = (itemId: string) =>
  http.delete<null>(`/transactions/item/${itemId}`)

export const createItem = (item: CreatableAPIItem) =>
  http.post<APIItem>(`/transactions/item`, item)

export const updateItem = (itemId: string, item: UpdatableAPIItem) =>
  http.patch<APIItem>(`/transactions/item/${itemId}`, item)

export const updateTransactionItems = (
  transactionId: string,
  itemList: string[]
) =>
  http.post<null>(`/transactions/transaction/${transactionId}/reorder`, {
    item_list: itemList,
  })

export const createItemDownload = (
  itemId: string,
  kind: ItemDownloadKindType
) => http.post<{}>(`/transactions/item/${itemId}/download`, { kind: kind })

export const createMultiItemDownload = (itemUuids: string[], kind: string) =>
  http.post<{
    item_uuids: string[]
  }>("/transactions/item/downloads", {
    item_uuids: itemUuids,
    kind: kind,
  })

export const createTask = (task: CreatableAPITask) =>
  http.post<APITask>(`/transactions/task`, task)

export const updateTask = (taskId: string, task: UpdatableAPITask) =>
  http.patch<APITask>(`/transactions/task/${taskId}`, task)

export const deleteTask = (taskId: string) =>
  http.delete<null>(`/transactions/task/${taskId}`)

export const getNotes = async (transactionId: string) =>
  (await http.get<APINote[]>(
    `/transactions/note?transaction=${transactionId}`
  )) || []

export const createNote = (note: CreatableAPINote) =>
  http.post<APINote>(`/transactions/note`, note)

// TODO-TS: convert signatory http requests
export const getTransactionDashboardSignatories = async (
  transactionId: string
) =>
  (await http.get<APISignatory[]>(
    `/signatory?transaction=${transactionId}&query=
    {uuid,email,full_name,is_org,organizations,organization_relations,
    address_1,address_2,city,state,postal_code,address,
    entity_description,custom_values,instapages_config,bulk_signatory_upload_id
    }`
  )) || []

export const getSignatory = (signatoryId: string) =>
  http.get<APISignatory>(`/signatory/${signatoryId}`)

export const getSignatories = async (transactionId: string) =>
  (await http.get<APISignatory[]>(`/signatory?transaction=${transactionId}`)) ||
  []

export const getSignatoryAuditLog = async (signatoryId: string) =>
  (await http.get<APISignerAuditLog[]>(
    `/signatory/${signatoryId}/audit_log`
  )) || []

export const getBulkSignatoryUploads = async (transactionId: string) =>
  (await http.get<APIBulkSignatoryUpload[]>(
    `/bulk_signatory_upload?transaction=${transactionId}`
  )) || []

export const createSignatory = (signatory: CreatableAPISignatory) =>
  http.post<APISignatory>(`/signatory`, signatory)

export const updateSignatory = (
  signatoryId: string,
  signatory: UpdatableAPISignatory
) => http.patch<APISignatory>(`/signatory/${signatoryId}`, signatory)

// TODO-TS: convert signatory representative http requests
export const createSignatoryRepresentative = (
  signatoryId: string,
  rep: CreatableAPIRepresentative
) =>
  http.post<APIRepresentativeDetails>(
    `/signatory/${signatoryId}/representative`,
    rep
  )

export const deleteSignatoryRepresentative = (
  signatoryId: string,
  repId: string
) => http.delete<null>(`/signatory/${signatoryId}/representative/${repId}`)

export const updateSignatoryRepresentative = (
  signatoryId: string,
  repId: string,
  title: string = ""
) =>
  http.patch<APIRepresentativeDetails>(
    `/signatory/${signatoryId}/representative/${repId}`,
    { title }
  )

export const replaceSignatoryRepresentative = (
  entityId: string,
  repId: string,
  newRepId: string
) =>
  http.post<null>("/signatory/replace-representative", {
    entity_pk: entityId,
    existing_representative_pk: repId,
    new_representative_pk: newRepId,
  })

export const getSigningGroups = async (transactionId: string) =>
  (await http.get<APISigningGroup[]>(
    `/signing_group?transaction=${transactionId}&query={uuid,name,members}`
  )) || []

export const getDocument = (docId: string) =>
  http.get<APIDocument>(`/transactions/document/${docId}`)

export const getDocuments = async (
  transaction?: string,
  includeThumbnails?: boolean,
  inQueueParam?: boolean
) => {
  if (transaction === undefined) {
    // early return to prevent the API call
    return []
  }

  let queryParams = new URLSearchParams({
    transaction,
  })

  if (inQueueParam !== undefined) {
    queryParams.set("in_queue", String(inQueueParam))
  }
  if (includeThumbnails !== undefined) {
    queryParams.set("include_thumbnails", String(includeThumbnails))
  }

  return (
    (await http.get<APIDocument[]>(`/transactions/document?${queryParams}`)) ||
    []
  )
}

export const getAssignments = async (transactionId: string) =>
  (await http.get<APIAssignment[]>(
    `/assignments/assignment?transaction=${transactionId}`
  )) || []

export const createAssignment = ({
  itemId,
  itemPageId,
  signatoryId,
  authRepId,
}: {
  itemId: string
  itemPageId: string
  signatoryId: string
  authRepId?: string
}) =>
  http.post<APIAssignment>(`/assignments/assignment`, {
    item: itemId,
    item_page_uuid: itemPageId,
    signatory: signatoryId,
    auth_rep: authRepId,
  })

export const createGroupAssignment = (groupId: string, itemId: string) =>
  http.post(`/signing_group/${groupId}/assign`, { item: itemId })

export const createGroupDynamicAssignments = async (
  groupId: string,
  itemId: string,
  pageId: string
) =>
  http.post<{ assignments: APIAssignment[] }>(
    `/signing_group/${groupId}/assign_dynamic`,
    {
      item: itemId,
      item_page_uuid: pageId,
    }
  )

export const deleteGroupDynamicAssignment = (groupAssignmentId: string) =>
  http.delete(`/assignments/dynamic_assignment/${groupAssignmentId}`)

export const updateGroupAssignments = async (
  groupAssignmentId: string,
  header: string
) =>
  http.patch(`/assignments/dynamic_assignment/${groupAssignmentId}`, {
    header,
  })

export const updateAssignment = (
  assignmentId: string,
  assignment: UpdatableAPIAssignment
) => http.patch(`/assignments/assignment/${assignmentId}`, assignment)

export const rejectAssignment = (assignmentId: string) =>
  http.get(`/assignments/assignment/${assignmentId}/reject`)

export const getSignatoryAssignments = (signatoryId: string) =>
  http.get(`/assignments/assignment?signatory=${signatoryId}`)

export const assignSignaturePage = (
  assignmentId: string,
  documentId: string,
  pageNumber: number
) =>
  http.patch(`/assignments/assignment/${assignmentId}`, {
    executed_document: documentId,
    executed_document_page: pageNumber,
  })

export const getOrg = (orgId: string) =>
  http.get<APIOrganization>(`/authentication/organization/${orgId}`)

export const getOrganizationTransactionActivity = (orgId: string) =>
  http.get(`/authentication/organization/${orgId}/transaction_report`)

export const getOrganizationTransactionReport = (orgId: string) =>
  http.get(
    `/authentication/organization/${orgId}/transaction_report?download=excel`
  )

export const deleteOrgMember = (id: string) =>
  // This is how we 'delete' a org user
  http.patch(`/authentication/user/${encodeURIComponent(id)}`, {
    organization: null,
  })

export const createPacket = (packet: APIPacketRequest) =>
  http.post("/assignments/create_packet", packet)

export const getBatchPacketRequests = (ids: string[]) =>
  Promise.all(
    ids.map((id) =>
      http.get<APIPacketRequestReponse>(`/assignments/create_packet/${id}`)
    )
  )

export const createTab = (tab: CreatableAPITab) =>
  http.post<APITab>("/tab", tab)

export const deleteTab = (tabId: string) => http.delete<null>(`/tab/${tabId}`)

export const updateTab = (tabId: string, tab: UpdatableAPITab) =>
  http.patch<APITab>(`/tab/${tabId}`, tab)

export const updateUser = (user: UpdatableAPIUser) =>
  http.patch<APIUser>(`/authentication/user/${user.uuid}`, user)

export const uploadIMangeFile = (data) => http.post("/dms/imanage/upload", data)

export const syncIManageTransactions = (orgId: string) =>
  http.post("/dms/imanage/sync", { orgId: orgId })

export const postPresignedUrl = async (
  file: {
    id: String
    fileData: File
  },
  transactionId: string
) => {
  try {
    return await http.post<{ url: string; fields: { [key: string]: string } }>(
      `/transactions/transaction/${transactionId}/presigned_url`,
      // file_name needs to include the extension
      { doc_uuid: file.id, file_name: file.fileData.name }
    )
  } catch (error) {
    console.error(error)
    throw new Error("Failed to get pre-signed url for uploads.")
  }
}

export const uploadFileToUrl = async (
  url: string,
  file: File,
  fields: { [key: string]: string }
) => {
  const formData = new FormData()

  Object.keys(fields).forEach((key) => {
    let val = fields[key]
    if (val) {
      formData.append(key, val)
    }
  })

  formData.append("file", new Blob([file]))

  const res = await fetch(url, {
    body: formData,
    method: "POST",
    headers: {}, // must clear for form data post to work
  })

  if (!res.ok) {
    throw new Error("Failed to get pre-signed URL")
  }
}

export const createDocument = async (doc: CreatableAPIDocument) => {
  return http.post<APIDocument>("/transactions/document", doc)
}

export const updateDocument = async (doc: UpdateableAPIDocument) =>
  http.patch(`/transactions/document/${encodeURIComponent(doc.uuid)}`, doc)

export const deleteDocument = async (docId: string) =>
  http.delete(`/transactions/document/${docId}`)

export const postParticipantSettings = async (transactionId: string, data) => {
  return http.post(
    `/transactions/transaction/${transactionId}/participant_settings`,
    data
  )
}

export const postPresignedSignatoryUrl = async (
  fileName: string,
  transactionId: string
) => {
  try {
    return await http.post(
      `/transactions/transaction/${transactionId}/presigned_signatory_url`,
      { file_name: fileName }
    )
  } catch (error) {
    console.error(error)
    throw new Error("Failed to get pre-signed url for signatory upload.")
  }
}

export const uploadSignatoriesFileByTransactionId = async (
  transactionId: string,
  fileKey: string
) => {
  return http.post(
    `/transactions/transaction/${transactionId}/upload_signatories`,
    { file_key: fileKey }
  )
}

export const uploadSignatoriesByTransactionId = async (
  data: APISignatoryUploadRow[]
) =>
  http.post<{ bulk_signatory_upload_id: string }>(
    `/signatory/bulk_create`,
    data
  )

export const postSignatory = async (data: CreatableAPISignatory) => {
  return http.post<APISignatory>("/signatory", data)
}

export const postSignatoryReminder = async (
  signatory: Signatory,
  data: { message: string }
) => {
  return http.post(`/signatory/${signatory.id}/send_reminder`, data)
}

export const deleteSignatory = async (id: string) => {
  return http.delete(`/signatory/${encodeURIComponent(id)}`)
}

export const postSigningGroup = async (data: CreatableAPISigningGroup) => {
  return http.post<APISigningGroup>("/signing_group", data)
}

export const postDuplicateSigningGroup = async (id: string) =>
  http.post<APISigningGroup>(`/signing_group/${id}/copy`, null)

export const postGroupMember = async (data: CreatableAPISigningGroupMember) => {
  return http.post<APISigningGroupMember>("/signing_group_member", data)
}

export const postBulkGroupsMembers = async (data: BulkSigningGroupsMembers) => {
  return http.post<BulkSigningGroupsMembers[]>(
    "/signing_group_member/bulk_create",
    data
  )
}

export const deleteGroupMember = async (id: string) => {
  return http.delete<null>(`/signing_group_member/${encodeURIComponent(id)}`)
}

export const updateGroupMember = async (id: string, authRep: string) => {
  // TODO: Figure out the return type
  return http.patch<unknown>(`/signing_group_member/${id}`, {
    auth_rep: authRep,
  })
}

export const cleanupGroupMemberOrder = async (transactionId: string) => {
  return http.post(
    `/transactions/transaction/${transactionId}/signing_group_order_cleanup`,
    null
  )
}

export const moveGroupMember = async (data: MoveAPISigningGroupMember) => {
  return http.post<APISigningGroup>(
    `/signing_group_member/${data.id}/move`,
    data
  )
}

export const postGroup = async (data: CreatableAPISigningGroup) => {
  return http.post<APISigningGroup>(`/signing_group`, data)
}

export const updateGroup = async (data: UpdatableAPISigningGroup) => {
  return http.patch<APISigningGroup>(
    `/signing_group/${encodeURIComponent(data.uuid)}`,
    data
  )
}

export const deleteGroup = async (id: string) => {
  return http.delete<null>(`/signing_group/${encodeURIComponent(id)}`)
}

export const getCustomFieldKeys = (transactionId: string) => {
  return http.get<APITransactionCustomField[]>(
    `/transactions/custom_key?transaction=${transactionId}`
  )
}

export const postBulkCustomFieldKey = (
  data: APICreateTransactionCustomField[]
) => {
  return http.post<APITransactionCustomField>(
    "/transactions/custom_key/bulk_create",
    data
  )
}

export const patchCustomFieldKey = (
  data: APIUpdatableTransactionCustomField
) => {
  return http.patch<APIUpdatableTransactionCustomField>(
    `/transactions/custom_key/${data.uuid}`,
    data
  )
}

export const deleteCustomFieldKey = (id: string) => {
  return http.delete<null>(`/transactions/custom_key/${id}`)
}

export const postCustomFieldValue = async (
  data: APICreateSignatoryCustomFieldValue
) => {
  return http.post<APISignatoryCustomFieldValue>(
    `/transactions/custom_value`,
    data
  )
}

export const patchCustomFieldValue = async (
  data: { uuid: string } & Partial<APISignatoryCustomFieldValue>
) => {
  return http.patch<APISignatoryCustomFieldValue>(
    `/transactions/custom_value/${data.uuid}`,
    data
  )
}

export const deleteCustomFieldValue = async (id: string) => {
  return http.delete<null>(`/transactions/custom_value/${id}`)
}

export const postFileShare = async (data) => {
  return http.post(
    `/notifications/${encodeURIComponent(data.id)}/share_file`,
    data
  )
}

export const updateNotification = async (
  id: string,
  data: UpdatableAPINotification
) => http.patch(`/notifications/${encodeURIComponent(id)}`, data)
