import Auth from "features/auth"

export class ApiError extends Error {
  status: number
  data: any

  constructor(message: string, status: number, data?: any) {
    super(message)
    this.name = "ApiError"
    this.status = status
    this.data = data

    // Required for proper prototype chain in TypeScript
    // and later checking for instanceof
    Object.setPrototypeOf(this, ApiError.prototype)
  }
}

export async function ApiFetch<T>(
  path: string,
  body?: unknown,
  options: {
    method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE"
  } = {}
) {
  let config = {
    headers: { "content-type": "application/json" },
    ...options,
    body: body ? JSON.stringify(body) : null,
  }

  await Auth.check()

  let response = await fetch("/api" + path, config)

  if (response.ok) {
    // check if response body has data
    if (response.status === 204) {
      return null
    }

    if (response.headers.get("Content-Length") === "0") {
      return null
    }

    let data = (await response.json()) as T

    return data
  }

  if (response.status === 302) {
    // get { url } from json
    return (await response.json()) as T
  }

  if (response.status === 400) {
    let errorData

    if (response.headers.get("Content-Type") === "application/json") {
      errorData = await response.json()
    }

    throw new ApiError("ApiFetch error", 400, errorData)
  }

  if (response.status === 401) {
    // unauthenticated
    if (typeof window !== "undefined") {
      if (window.config?.APP_ENV !== "EMBED") {
        await Auth.signOut()
        window.Beacon?.("logout")
        window.location.href = "/login?action=inactive"

        throw new ApiError("ApiFetch unauthenticated", 401)
      }
    }
  }

  if (response.status === 422) {
    let errorMessage = await response.json().then((data) => {
      return data.error_message
    })

    throw new ApiError("ApiFetch error", 422, errorMessage)
  }

  throw new ApiError("ApiFetch error general", response.status)
}

export const ApiHttp = {
  get: <T>(path: string) => ApiFetch<T>(path, null, { method: "GET" }),
  post: <T>(path: string, body: unknown) =>
    ApiFetch<T>(path, body, { method: "POST" }),
  patch: <T>(path: string, body: unknown) =>
    ApiFetch<T>(path, body, { method: "PATCH" }),
  put: <T>(path: string, body: unknown) =>
    ApiFetch<T>(path, body, { method: "PUT" }),
  delete: <T>(path: string) => ApiFetch<T>(path, null, { method: "DELETE" }),
}
