import * as React from "react"
import useToaster from "helpers/toaster"

import { useCreateItemsFromDocuments } from "../api"

import { FileStatus, statusPercentComplete } from "../operations"
import { FileDoc } from "models/FileDoc"
import pluralize from "helpers/pluralize"
import { ApiError } from "lib/api/api-http"

export const PER_PAGE_CONVERSION_TIME = process.env.PER_PAGE_CONVERSION_TIME
  ? Number(process.env.PER_PAGE_CONVERSION_TIME)
  : 400
export const INTERVAL = 1000

export function useUploadPercentComplete(
  status: FileStatus,
  numPages: FileDoc["numPages"]
) {
  let [percentComplete, setPercentComplete] = React.useState(
    statusPercentComplete(status)
  )
  let ticksRef = React.useRef<number | null>(null)
  let percentCompletePerTickRef = React.useRef<number | null>(null)
  let percentRemaining = 100 - statusPercentComplete(status)

  React.useEffect(() => {
    function recalculatePercentComplete() {
      if (
        ticksRef?.current &&
        percentCompletePerTickRef?.current &&
        ticksRef.current > 0
      ) {
        setPercentComplete(
          statusPercentComplete(status) +
            percentRemaining -
            ticksRef.current * percentCompletePerTickRef.current
        )
        ticksRef.current = ticksRef.current - 1
      }
    }

    if (status === "SUCCESS_CONVERTING_DOCUMENT" && !ticksRef.current) {
      if (ticksRef.current === 0) return // never complete

      ticksRef.current = Math.ceil(
        ((numPages ?? 0) * PER_PAGE_CONVERSION_TIME) / INTERVAL
      )

      if (!percentCompletePerTickRef.current) {
        percentCompletePerTickRef.current = percentRemaining / ticksRef.current
      }

      let timer = setInterval(recalculatePercentComplete, INTERVAL)

      return () => clearInterval(timer)
    } else {
      setPercentComplete(statusPercentComplete(status))
    }
  }, [status])

  return percentComplete
}

type UseAddToTransactionArg = {
  documentIds: string[]
  onStart?: () => void
  onFailure?: (e: ApiError) => void
  onSuccess?: (data: { document_id: string; item_id: string }[]) => void
}
export function useAddToTransaction({
  documentIds = [],
  onStart = () => {},
  onFailure = () => {},
  onSuccess = () => {},
}: UseAddToTransactionArg) {
  const { failure: toasterFailure, success } = useToaster()
  const { mutate: createItems } = useCreateItemsFromDocuments({
    onError: (e) => {
      toasterFailure(
        "There was a problem adding the documents to the transaction."
      )
      if (e instanceof ApiError) {
        onFailure(e)
      }
    },
    onSuccess: (data) => {
      success(
        <>
          <b>{`${data.length} ${pluralize("document", data.length)}`}</b>{" "}
          successfully added to transaction.
        </>
      )
      onSuccess(data)
    },
  })

  return function () {
    if (documentIds.length === 0) return

    onStart()
    createItems(documentIds)
  }
}

export interface UpdaterFile {
  id: string
}

export function useQueueUpdate<F extends UpdaterFile>(
  updater: (f: F) => void = () => null,
  persistedFiles: F[] = []
) {
  let [queue, setQueue] = React.useState<F[]>([])

  const addToQueue = (f: F) => {
    setQueue((q) => [...q, f])
  }

  let pluck = React.useCallback((files: F[]) => {
    let fileIds = files.map((f) => f.id)
    setQueue((q) => q.filter((f) => !fileIds.includes(f.id)))
  }, [])

  React.useEffect(() => {
    if (queue.length > 0 && persistedFiles.length > 0) {
      let updateableFiles = queue.filter(
        (file) => !!persistedFiles.find((f) => f.id === file.id)
      )

      updateableFiles.forEach((file) => {
        console.info(`Queuing file update for ${file.id}`)
        updater(file)
      })

      if (updateableFiles.length > 0) {
        pluck(updateableFiles)
      }
    }
  }, [queue, persistedFiles, updater, pluck])

  return { queue, addToQueue }
}
