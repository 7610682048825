import { Button, Icon, Intent, Tag } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { DownloadMenu } from "components/closing-binder"
import { updateTransaction, setTransactionArchive } from "helpers/api"
import { useRouter } from "next/router"

import { useEffect, useState } from "react"
import { Tooltip2 } from "@blueprintjs/popover2"

import TransactionPrefetchLink, {
  prefetchTransactionQueries,
} from "features/transactions/TransactionPrefetchLink"
import {
  TransactionCard,
  TransactionDeleteModal,
  TransactionEditModal,
  TransactionListCard,
} from "."
import TransactionDeactivatedLockIcon from "./TransactionDeactivatedLockIcon"
import {
  Transaction,
  TransactionKind,
  UpdatableTransaction,
} from "models/Transaction"
import { ProjectEditModal } from "features/projects/ProjectEditModal"
import useToaster from "helpers/toaster"
import { useCurrentUser } from "features/auth/withAuth"
import { useLeaveTransactionAsParticipant } from "features/transaction/id/api"

const LastViewedContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
  width: 100%;
`

type TransactionBrickProps = {
  apiUrl?: string
  lastVisited?: boolean
  onDelete?: (id: string) => void
  onVisit?: () => void
  revalidate?: () => void
  view?: string
  hideClientMatterNumberColumn?: boolean
} & Transaction

export const TransactionBrick = ({
  archivedFilesUrl,
  archived,
  clientName,
  clientNumber,
  closingBinder,
  createdAt,
  createdBy,
  description,
  expiredAt,
  id,
  isDeedTransaction,
  isSample,
  kind,
  lastVisited = false,
  matterNumber,
  name,
  organization,
  outstandingSignatures,
  onDelete = () => null,
  revalidate = () => null,
  view,
  hideClientMatterNumberColumn = false,
}: TransactionBrickProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editProjectModalOpen, setEditProjectModalOpen] = useState(false)

  useEffect(() => {
    if (lastVisited && id) {
      prefetchTransactionQueries(id)
    }
  }, [lastVisited, id])

  const router = useRouter()
  const { success } = useToaster()

  let { currentUser } = useCurrentUser()

  const { mutateAsync: leaveTransactionAsParticipant } =
    useLeaveTransactionAsParticipant({
      onSuccess: () => {
        success(
          `You have been removed as a participant from the ${name} ${
            kind === TransactionKind.WIRES ? "project" : "transaction"
          }`
        )
        revalidate()
      },
    })

  const handleDeleteItem = async () => {
    setDeleteModalOpen(false)
    onDelete(id)
  }

  const handleEditSubmit = async ({
    name,
    clientName: client_name,
    clientNumber: client_number,
    matterNumber: matter_number,
    description,
    isDeedTransaction: is_deed_transaction,
    isSample: is_sample,
  }: UpdatableTransaction) => {
    setEditModalOpen(false)
    setEditProjectModalOpen(false)

    await updateTransaction(id, {
      uuid: id,
      name: is_sample ? `${name} [Test]` : name,
      client_name,
      client_number,
      matter_number,
      description,
      is_deed_transaction,
      is_sample,
    })

    revalidate()
  }

  const handleArchiveToggle = async () => {
    await setTransactionArchive(id, !archived)

    revalidate()
  }

  const handleParticipantLeave = async () => {
    leaveTransactionAsParticipant(id)
  }

  const NameComponent = expiredAt ? "div" : TransactionPrefetchLink

  const isTransactionCreator = createdBy?.id === currentUser?.uuid
  // prevent someone who is an org admin for a different org from deleting a transaction,
  // i.e. a SimplyAgree admin helping a customer with their transaction
  const isOrgAdminForTransactionOrg =
    currentUser?.organization === organization && currentUser?.is_org_admin

  return (
    <>
      <TransactionDeleteModal
        transactionName={name}
        kind={kind}
        isOpen={deleteModalOpen}
        onSubmit={handleDeleteItem}
        onClose={() => setDeleteModalOpen(false)}
      />
      <ProjectEditModal
        transaction={{
          id,
          name,
          description,
          isSample,
        }}
        isOpen={editProjectModalOpen}
        onSubmit={handleEditSubmit}
        onClose={() => setEditProjectModalOpen(false)}
      />
      <TransactionEditModal
        transaction={{
          id: id,
          name,
          clientName,
          clientNumber,
          matterNumber,
          description,
          isDeedTransaction,
          isSample,
          // TODO-TS
          // expiredAt: expiredAt || null,
        }}
        isOpen={editModalOpen}
        onSubmit={handleEditSubmit}
        onClose={() => setEditModalOpen(false)}
      />

      {view === "GRID" ? (
        <TransactionCard
          key={id}
          interactive
          view={view}
          isExpired={Boolean(expiredAt)}
        >
          <NameComponent id={id} kind={kind}>
            <TransactionCard.Title text={name}>
              {expiredAt && (
                <TransactionDeactivatedLockIcon
                  wrapperClassName="!hidden group-hover:!block"
                  expiredAt={expiredAt}
                />
              )}
              <TransactionCard.Menu
                id={id}
                kind={kind}
                archived={archived}
                expiredAt={expiredAt}
                archivedFilesUrl={archivedFilesUrl}
                onDelete={() => setDeleteModalOpen(true)}
                showDeleteOption={
                  isTransactionCreator || isOrgAdminForTransactionOrg
                }
                onArchive={handleArchiveToggle}
                onLeave={handleParticipantLeave}
                onEdit={() =>
                  kind === TransactionKind.WIRES
                    ? setEditProjectModalOpen(true)
                    : setEditModalOpen(true)
                }
              />
            </TransactionCard.Title>
          </NameComponent>

          <TransactionCard.Body>
            {clientName && (
              <TransactionCard.ClientName>
                {clientName}
              </TransactionCard.ClientName>
            )}

            {clientNumber && (
              <p>{`${clientNumber}${
                matterNumber ? ` - ${matterNumber}` : ""
              }`}</p>
            )}

            {description && (
              <TransactionCard.Description>
                {description}
              </TransactionCard.Description>
            )}

            {(outstandingSignatures || 0) > 0 && !expiredAt && (
              <Button
                minimal
                intent={Intent.WARNING}
                onClick={() =>
                  router.push(
                    `/transaction/[id]/manage-signatures`,
                    `/transaction/${id}/manage-signatures`
                  )
                }
              >
                <Tag minimal round intent={Intent.WARNING}>
                  {outstandingSignatures}
                </Tag>{" "}
                Review eSignatures
              </Button>
            )}

            {closingBinder &&
              Object.keys(closingBinder?.downloads || {}).length > 0 &&
              !expiredAt && (
                <DownloadMenu
                  id={closingBinder.notification_uuid}
                  downloadUrls={closingBinder.downloads}
                  extraData={{ target: { name, transactionId: id } }}
                  type="closing_binder_download"
                  linkText=""
                  meta={
                    <div className="font-light italic">
                      This will download the binder titled{" "}
                      <span className="font-bold">
                        {closingBinder.nickname}
                      </span>
                      {closingBinder.last_build_date && (
                        <span className="text-sm">
                          , last built on{" "}
                          {new Intl.DateTimeFormat("en-US", {
                            dateStyle: "long",
                            timeStyle: "short",
                          }).format(new Date(closingBinder.last_build_date))}
                        </span>
                      )}
                    </div>
                  }
                >
                  <Button minimal icon="download">
                    Download closing binder
                  </Button>
                </DownloadMenu>
              )}

            {lastVisited && (
              <LastViewedContainer>
                <Tag minimal intent={Intent.PRIMARY}>
                  Last Viewed
                </Tag>
              </LastViewedContainer>
            )}
          </TransactionCard.Body>
        </TransactionCard>
      ) : (
        <div className="relative w-full">
          {lastVisited && (
            <div className="absolute -left-7 top-1/2 -translate-y-1/2 transform pb-2">
              <Tooltip2 content="Last viewed" position="top">
                <Icon icon="eye-on" color="#5a829e" size={18} />
              </Tooltip2>
            </div>
          )}
          <TransactionListCard
            archived={archived}
            archivedFilesUrl={archivedFilesUrl}
            clientName={clientName}
            clientNumber={clientNumber}
            closingBinder={closingBinder}
            createdAt={createdAt}
            createdBy={createdBy}
            description={description}
            expiredAt={expiredAt}
            handleArchiveToggle={handleArchiveToggle}
            id={id}
            matterNumber={matterNumber}
            name={name}
            setDeleteModalOpen={setDeleteModalOpen}
            setEditModalOpen={setEditModalOpen}
            outstandingSignatures={outstandingSignatures}
            view={view}
            kind={kind}
            hideClientMatterNumberColumn={hideClientMatterNumberColumn}
          />
        </div>
      )}
    </>
  )
}
