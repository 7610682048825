export type APISigningGroupMember = {
  uuid: string
  auth_rep: string | null
  group: string
  order: number
  signatory: string
}

export type SigningGroupMember = {
  uuid: string
  authRep?: string | null
  group: string
  order: number
  signatory: string
  memberId?: string
  signatoryId?: string
  authRepId?: string | null
}

export type BulkSigningGroupsMembers = {
  transaction: string
  groups: string[]
  members: Array<{
    signatory: string
    auth_rep: string | null
  }>
}

export type CreatableAPISigningGroupMember = Pick<
  APISigningGroupMember,
  "signatory" | "group"
> &
  Pick<Partial<APISigningGroupMember>, "auth_rep">

export type CreatableSigningGroupMember = Pick<
  SigningGroupMember,
  "signatory" | "group"
> &
  Pick<Partial<SigningGroupMember>, "authRepId">

export type MoveAPISigningGroupMember = {
  id: string
  type: "before" | "after"
  target: string
  from_indez: number
  to_index: number
}

export type MoveSigningGroupMember = {
  memberId: string
  type: "before" | "after"
  targetId: string
  fromIndex: number
  toIndex: number
}

export const decode = (member: APISigningGroupMember): SigningGroupMember => ({
  ...member,
  memberId: member.uuid,
  signatoryId: member.signatory,
  authRepId: member.auth_rep,
})

export const encode = (member: SigningGroupMember): APISigningGroupMember => ({
  uuid: member.uuid,
  auth_rep: member.authRep || null,
  group: member.group,
  order: member.order,
  signatory: member.signatory,
})

export const encodeCreate = (
  member: CreatableSigningGroupMember
): CreatableAPISigningGroupMember => ({
  signatory: member.signatory,
  auth_rep: member.authRepId || null,
  group: member.group,
})

export const encodeMove = (
  member: MoveSigningGroupMember
): MoveAPISigningGroupMember => ({
  id: member.memberId,
  type: member.type,
  target: member.targetId,
  from_indez: member.fromIndex,
  to_index: member.toIndex,
})
