import { SigningType } from "models/Document"
import { FileDoc } from "models/FileDoc"
import DocumentsQueueEmptyState from "./DocumentsQueueEmptyState"
import DocumentsQueueHeader from "./DocumentsQueueHeader"
import DocumentQueueCard from "./DocumentQueueCard"
import DocumentsQueueFooter from "./DocumentsQueueFooter"
import { ChangeEvent } from "react"
import { useTransactionIdParam } from "helpers/params"
import { useUploadQueueContext } from "../UploadQueueContext"

type DocumentsQueueProps = {
  handleDeleteDocuments: () => void
  handleToggleSelectAll: (evt: ChangeEvent<HTMLInputElement>) => void
  handleUpdateDocument: (file: FileDoc) => Promise<void>
  onAddToTransaction: () => void
  onBulkUpdateDocuments: ({ signingType }: { signingType: SigningType }) => void
  onOpenDMS: () => void
  onToggleDocument: (f: FileDoc) => void
  openFileDialog: () => void
}

export default function DocumentsQueue({
  handleDeleteDocuments,
  handleToggleSelectAll,
  handleUpdateDocument,
  onAddToTransaction,
  onBulkUpdateDocuments,
  onOpenDMS,
  onToggleDocument,
  openFileDialog,
}: DocumentsQueueProps) {
  const transactionId = useTransactionIdParam()
  const { state } = useUploadQueueContext()
  const handleDocTypeChange = (file: FileDoc) => (signingType: SigningType) => {
    handleUpdateDocument({
      id: file.id,
      signingType,
      name: file.name,
      status: file.status,
      uploadOrder: file.uploadOrder,
    })
  }

  function handleBulkActionSelection(signingType: SigningType) {
    onBulkUpdateDocuments({ signingType })
  }

  function handleToggleDocument(id: string) {
    const file = state[transactionId]?.files?.find((f) => f.id === id)
    if (file) {
      onToggleDocument(file)
    }
  }

  const nonRemovedFiles =
    state[transactionId]?.files?.filter((f) => f.status !== "REMOVED") || []

  return (
    <>
      {nonRemovedFiles?.length !== 0 ? (
        <div className="flex w-full">
          <div className="grid w-full grid-rows-[auto_1fr_auto] ">
            <DocumentsQueueHeader
              handleBulkActionSelection={handleBulkActionSelection}
              handleToggleSelectAll={handleToggleSelectAll}
              handleDeleteDocuments={handleDeleteDocuments}
            />
            <div className="overflow-y-scroll">
              {nonRemovedFiles
                .sort((a, b) => a.uploadOrder - b.uploadOrder)
                .map((file, index) => (
                  <DocumentQueueCard
                    isLastCard={
                      nonRemovedFiles.length > 1
                        ? index === nonRemovedFiles.length - 1
                        : false
                    }
                    key={file.id}
                    file={file}
                    onSelect={handleToggleDocument}
                    onUpdate={handleUpdateDocument}
                    selectedOption={file.signingType}
                    onSelection={handleDocTypeChange(file)}
                    onAddToTransaction={onAddToTransaction}
                  />
                ))}
            </div>
            <DocumentsQueueFooter
              onOpenDMS={onOpenDMS}
              openFileDialog={openFileDialog}
              onAddToTransaction={onAddToTransaction}
            />
          </div>
        </div>
      ) : (
        <DocumentsQueueEmptyState
          onOpenDMS={onOpenDMS}
          openFileDialog={openFileDialog}
        />
      )}
    </>
  )
}
