import React, { ChangeEvent, useEffect } from "react"
import { DropzoneRootProps } from "react-dropzone"

import { cEventTrack } from "app/user-tracking/index"
import DMSApplicationModal from "features/dms/application"
import useToaster from "helpers/toaster"
import { useQueuedDocuments } from "features/add-documents/api"
import { Container } from "./ui"
import { documentsToFileDocs } from "./reducer"
import { FileDoc } from "models/FileDoc"
import { isPersistable, triggerQueuedUpload } from "./operations"
import { useAddToTransaction, useQueueUpdate } from "./hooks"
import {
  useBulkUpdateDocuments,
  useDeleteDocuments,
  useUpdateDocument,
} from "./api"
import { SigningType } from "models/Document"
import { User } from "models/User"

import { useUploadQueueState } from "./useUploadQueueState"
import DocumentsQueue from "./DocumentQueue"
import DocumentsQueueDragActiveState from "./DocumentQueue/DocumentsQueueDragActiveState"
import { countOfInProgressUploads } from "./utils"
import {
  useIsUserOnTransactionIdPage,
  useTransactionIdParam,
} from "helpers/params"
import { useUploadQueueContext } from "./UploadQueueContext"

export const DOCUMENT_POLLING_INTERVAL = 3000

type UploadQueueManagerProps = {
  user: User
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  isDragActive: boolean
  open: () => void
  handleOpenDMS: () => void
}

export default function UploadQueueManager({
  user,
  getRootProps,
  isDragActive,
  open,
  handleOpenDMS,
}: UploadQueueManagerProps) {
  const { isUserOnTransactionIdPage } = useIsUserOnTransactionIdPage()
  const { state, dispatch } = useUploadQueueContext()
  const transactionId = useTransactionIdParam()
  const {
    isPollingForDocuments,
    setIsPollingForDocuments,
    setIsLoading,
    isNativeDMSAppOpen,
    setIsNativeDMSAppOpen,
    isPollingForExternalDMS,
    setDrawerIsOpen,
  } = useUploadQueueState()
  const { failure: toasterFailure, success } = useToaster()

  const nonRemovedFiles =
    state[transactionId]?.files?.filter((f) => f.status !== "REMOVED") ?? []

  const countOfConversionComplete = nonRemovedFiles.filter(
    (f) => f.status === "CONVERSION_COMPLETE"
  ).length

  const queuedUploads = nonRemovedFiles.filter((f) => f.status === "QUEUED")
  const inProgressCount = countOfInProgressUploads(nonRemovedFiles)
  const hasInProgressUploads = !!inProgressCount

  const pollInterval =
    isPollingForDocuments || isPollingForExternalDMS
      ? DOCUMENT_POLLING_INTERVAL
      : undefined

  const { data } = useQueuedDocuments({
    pollInterval,
    transactionId,
    enabled: isUserOnTransactionIdPage,
  })

  useEffect(() => {
    setIsPollingForDocuments(hasInProgressUploads)
  }, [hasInProgressUploads])

  useEffect(() => {
    if (data) {
      dispatch({
        type: "MERGE_DOCUMENTS",
        documents: documentsToFileDocs(data),
        transactionId,
      })
      setIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (inProgressCount < 6 && queuedUploads.length > 0) {
      const nextFile = queuedUploads[0]
      if (nextFile) {
        console.info(`TRIGGERING UPLOAD FOR ${nextFile.id}`)
        dispatch({
          type: "UPDATE_FILE",
          file: { ...nextFile, status: "IN_PROGRESS" },
          transactionId,
        })
        triggerQueuedUpload(nextFile, dispatch, transactionId)
      }
    }
  }, [
    inProgressCount,
    countOfConversionComplete,
    queuedUploads,
    dispatch,
    transactionId,
  ])

  const selectedDocuments = nonRemovedFiles.filter((f) => f.isSelected)
  const selectedPersistedDocuments = selectedDocuments.filter(isPersistable)
  const selectedPersistedDocumentIds = selectedPersistedDocuments.map(
    (d) => d.id
  )

  const { mutateAsync: bulkDeleteDocuments } = useDeleteDocuments(
    transactionId,
    {
      onError: () => {
        toasterFailure("There was a problem deleting those documents")
        setIsLoading(false)
      },
    }
  )

  function handleDeleteDocuments() {
    const documentIds = selectedDocuments.map((f) => f.id)
    const persistedDocumentIds = selectedPersistedDocuments.map((f) => f.id)
    dispatch({ type: "REMOVE_DOCUMENTS", documentIds, transactionId })

    if (persistedDocumentIds.length > 0) {
      setIsLoading(true)
      bulkDeleteDocuments(persistedDocumentIds).then(() => {
        const count = documentIds.length
        success(
          <>
            <strong>{`${count} document${count > 1 ? "s" : ""}`}</strong>{" "}
            {count > 1 ? "have" : "has"} been deleted from the Upload Queue.
          </>
        )
      })
    }
  }

  const { mutateAsync: updateDocument } = useUpdateDocument({
    onError: () => {
      toasterFailure("There was a problem saving your changes")
      setIsLoading(false)
    },
  })

  const { addToQueue: queueUpdate } = useQueueUpdate(
    ({ id, name, signingType }) => updateDocument({ id, name, signingType }),
    nonRemovedFiles.filter(isPersistable)
  )

  async function handleUpdateDocument(file: FileDoc) {
    dispatch({ type: "UPDATE_FILE", file, transactionId })
    if (isPersistable(file)) {
      setIsLoading(true)
      await updateDocument({
        id: file.id,
        name: file.name,
        signingType: file.signingType,
      })
    } else {
      queueUpdate(file)
    }
  }

  function handleToggleDocument(doc: FileDoc) {
    dispatch({
      type: "UPDATE_FILE",
      file: { ...doc, isSelected: !doc.isSelected },
      transactionId,
    })
  }

  function handleToggleSelectAll(event: ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: "BULK_UPDATE_DOCUMENTS",
      properties: { isSelected: event.target.checked },
      transactionId,
    })
  }

  function handleDMSPendingUploads(dmsDocs: FileDoc[]) {
    dispatch({
      type: "ADD_FILES",
      files: dmsDocs.map((d) => ({
        ...d,
        signingType: "to_be_signed",
        status: "SELECTED_FROM_DMS",
      })),
      transactionId,
    })
  }

  const { mutate: bulkUpdateDocuments } = useBulkUpdateDocuments({
    onError: () => {
      toasterFailure("There was a problem saving your changes")
      setIsLoading(false)
    },
  })

  function handleBulkUpdateDocuments(properties: { signingType: SigningType }) {
    const documents = nonRemovedFiles
      .filter(isPersistable)
      .map((f) => ({ ...f, ...properties }))
      .map(({ id, name, signingType }) => ({ id, name, signingType }))

    const localDocuments = nonRemovedFiles.filter((f) => !isPersistable(f))

    dispatch({ type: "BULK_UPDATE_DOCUMENTS", properties, transactionId })

    localDocuments.forEach((doc) => queueUpdate({ ...doc, ...properties }))

    if (documents.length > 0) {
      setIsLoading(true)
      bulkUpdateDocuments(documents)
    }
  }

  const addToTransaction = useAddToTransaction({
    documentIds: selectedPersistedDocumentIds,
    onStart: () => setIsLoading(true),
    onFailure: () => {
      setTimeout(() => setIsLoading(false), 3000)
    },
    onSuccess: (data: { document_id: string; item_id: string }[]) => {
      cEventTrack(user, "DOCUMENTS_ADDED", {
        transactionName: transactionId,
        documentsCount: data.length,
        uploadMethod: "",
      })

      dispatch({
        type: "REMOVE_DOCUMENTS",
        documentIds: data.map((d) => d.document_id),
        transactionId,
      })

      const isDocumentQueueEmpty = nonRemovedFiles.every(
        (f) => !!data.find((d) => d.document_id === f.id)
      )
      if (isDocumentQueueEmpty) {
        // close drawer just in case
        setDrawerIsOpen(false)
      }
      setTimeout(() => setIsLoading(false), 3000)
    },
  })

  return (
    <>
      <Container {...getRootProps()} active={isDragActive}>
        {isDragActive && <DocumentsQueueDragActiveState />}

        {isNativeDMSAppOpen && (
          <DMSApplicationModal
            onClose={() => setIsNativeDMSAppOpen(false)}
            onAddPendingDMSDocs={handleDMSPendingUploads}
          />
        )}

        <DocumentsQueue
          onOpenDMS={handleOpenDMS}
          openFileDialog={open}
          onAddToTransaction={addToTransaction}
          handleDeleteDocuments={handleDeleteDocuments}
          handleUpdateDocument={handleUpdateDocument}
          onBulkUpdateDocuments={handleBulkUpdateDocuments}
          onToggleDocument={handleToggleDocument}
          handleToggleSelectAll={handleToggleSelectAll}
        />
      </Container>
    </>
  )
}
