export type APIBulkSignatoryUpload = {
  uuid: string
  created_at: string
}

export type BulkSignatoryUpload = {
  id: string
  uploadedAt: string
}

export const decode = (input: APIBulkSignatoryUpload): BulkSignatoryUpload => ({
  id: input.uuid,
  uploadedAt: input.created_at,
})
