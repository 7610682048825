import styled from "@emotion/styled"
import { Colors } from "@blueprintjs/core"
import Link from "next/link"
import { Tooltip2 } from "@blueprintjs/popover2"

import EmbeddedAttachmentsIcon from "icons/embedded-attachments.svg"

export const DropContainer = styled.div<{ isOver?: boolean }>`
  counter-reset: closing-binder-counter;
  border-radius: 0.25rem;
  ${(props) =>
    props.isOver &&
    `background: rgba(189, 107, 189, 0.3);`}; /* Colors.VIOLET4 at 70% transparency*/
`

export const InsertBar = styled.div<{ depth?: number }>`
  border: 1px solid ${Colors.VIOLET4};
  top: -1px;
  pointer-events: none;
  position: absolute;
  width: calc(100% + 0.5rem);
  margin-left: -0.25rem;
  z-index: 1;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: -0.25rem;
    left: -0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${Colors.VIOLET4};
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${Colors.VIOLET4};
  }
`

export const SupplementalDocumentsList = styled.div<{ isOver?: boolean }>`
  border-radius: 0.25rem;
  border: 1px solid transparent;

  ${(props) =>
    props.isOver &&
    /* Colors.VIOLET4 at 70% transparency */
    `background: rgba(189, 107, 189, 0.3); 
    border: 1px solid ${Colors.VIOLET4};
    `}
`

export const renderLink = ({
  name,
  id,
  pageId,
  embeddedPageId,
  transactionId,
  isEmbedded,
}: {
  name: string
  id: string
  pageId?: string
  embeddedPageId?: string
  transactionId: string
  isEmbedded: boolean
}) => {
  return (
    <Link
      href={`/transaction/${transactionId}/item/${id}${
        pageId ? `?pageId=${pageId}` : ""
      }${embeddedPageId ? `&embeddedPageId=${embeddedPageId}` : ""}`}
      data-tracking-action-name={`sa-evt-dashboard-item-name=${id}`}
      className="hover:no-underline flex items-center"
      onClick={(evt) => {
        if (isEmbedded) {
          evt.stopPropagation()
          evt.preventDefault()
        }

        if (evt.metaKey || evt.ctrlKey || evt.shiftKey) {
          evt.preventDefault()
        } else {
          evt.stopPropagation()
        }
      }}
    >
      <p className="pr-2 overflow-auto truncate">{name}</p>
      {pageId && embeddedPageId && (
        <Tooltip2
          content="Internal attachment"
          position="top"
          hoverOpenDelay={500}
          className="ml-auto"
        >
          <EmbeddedAttachmentsIcon className="text-[#5C7080]" />
        </Tooltip2>
      )}
    </Link>
  )
}
